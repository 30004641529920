import React from "react";
import theme from "theme";
import { Theme, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"sartlar-ve-kosullar"} />
		<Helmet>
			<title>
				Higland Golf Kulübü
			</title>
			<meta name={"description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:title"} content={"Higland Golf Kulübü"} />
			<meta property={"og:description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
		</Helmet>
		<Components.Headernew />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			{"    "}{"    "}
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				{"        "}Şartlar ve Koşullar{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				{"        "}HIGHLAND GOLF KULÜBÜ olarak, üyelerimizin ve misafirlerimizin hizmetlerimizi kullanırken uyacakları şartlar ve koşulları belirlemek için aşağıdaki kuralları oluşturduk. Kulübümüzü kullanmanız bu şartları kabul ettiğiniz anlamına gelir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Üyelik ve Kullanım Şartları{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}HIGHLAND GOLF KULÜBÜ’ne üye olmak veya kulüp hizmetlerinden yararlanmak isteyen herkes, kulübün belirlediği şartlara uymayı kabul eder. Üyelik, kişisel ve başkalarına devredilemez. Üyelik ücretleri, kulüp tarafından belirlenen tarihlerde ödenmelidir. Üyelik süresi boyunca kulüp kurallarına uyulması zorunludur.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Rezervasyon ve İptaller{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Kulüp sahaları ve tesisleri için yapılan rezervasyonlar, belirlenen süre içinde yapılmalıdır. Rezervasyon iptalleri, kulübün belirlediği iptal politikasına uygun olarak gerçekleştirilmelidir. İptallerin zamanında yapılmaması durumunda, kulüp iptal ücretleri uygulama hakkını saklı tutar.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Davranış ve Disiplin{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}HIGHLAND GOLF KULÜBÜ üyelerinin ve misafirlerinin, diğer üyeler ve personel ile saygılı ve nazik bir şekilde davranması beklenir. Kulüp tesislerinde uygunsuz davranışlar veya kurallara aykırı hareketler, üyelik iptaline veya kulüp hizmetlerinden men edilmeye neden olabilir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Sorumluluk Reddi{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}HIGHLAND GOLF KULÜBÜ, kulüp tesislerinin kullanımı sırasında meydana gelebilecek herhangi bir kaza, yaralanma veya kayıptan sorumlu değildir. Üyeler ve misafirler, kulüp tesislerini kendi sorumlulukları altında kullanır ve tüm önlemleri almakla yükümlüdürler.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Gizlilik ve Veri Koruma{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}HIGHLAND GOLF KULÜBÜ, üyelerin ve misafirlerin kişisel bilgilerini gizli tutmayı ve bu bilgileri yalnızca kulüp hizmetlerinin sunulması amacıyla kullanmayı taahhüt eder. Kişisel verilerin korunması konusunda ilgili yasal düzenlemelere uygun hareket edilmektedir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Değişiklikler ve Güncellemeler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}HIGHLAND GOLF KULÜBÜ, bu şartlar ve koşulları önceden bildirimde bulunmaksızın değiştirme hakkını saklı tutar. Yapılan değişiklikler, bu sayfada yayınlandığı tarihten itibaren geçerli olacaktır. Üyeler ve misafirler, şartlar ve koşullardaki değişiklikleri düzenli olarak kontrol etmekle sorumludur.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}İletişim{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu şartlar ve koşullar hakkında herhangi bir sorunuz veya endişeniz varsa, bizimle iletişime geçebilirsiniz:{" "}
				<Link href="mailto:info@highlandgolf.com">
					info@highlandgolf.com
				</Link>
				{"    "}
			</Text>
		</Section>
		<Components.Headernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});